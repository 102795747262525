import React from 'react';
import styled from '@emotion/styled';

import SEO from '@components/SEO';
import Layout from '@components/Layout';
import Subscription from '@components/Subscription';

function SubscriptionPage({ location }) {
  return (
    <Layout>
      <SEO pathname={location.pathname} title="Subscribe 📭" />
      <Space>
        <Subscription />
      </Space>
      <Gradient />
    </Layout>
  );
}

export default SubscriptionPage;

const Space = styled.div`
  margin-top: 90px;
`;

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`;
